import { VERSION } from "./version";

export const environment = {
  production: false,
  envName: "QA",
  apiUrl: 'https://gia-api-qa.cbre.com',
  issuer: "https://login-uat.cbre.com/oauth2/default",
  clientId: "0oa22z632d933XNYm0h8",
  redirectUri: window.location.origin + '/',
  appVersion: VERSION.version,
  minutesUntilAutoLogout: 20
};
