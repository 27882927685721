import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "../shared/auth.service";
import { Router } from "@angular/router";
import { UserAgentApplication } from "msal";
import * as _ from "lodash";
import { AppSettingsService } from "../shared/app.settings.service";
import { AppRegistration } from "../models/AuthzUserInfo.model";
import { NgxSpinnerService } from "ngx-spinner";
import OktaAuth from "@okta/okta-auth-js";
import { OKTA_AUTH } from "@okta/okta-angular";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  public user: UserAgentApplication = null;
  public userInfo: any = null;
  public apiCallFailed: boolean;
  public loginFailed: boolean;
  public token: string;
  public authorizationStatusMessage: string;
  public app_name: string;

  constructor(
    @Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    public authService: AuthService,
    private router: Router,
    public appSettingsService: AppSettingsService,
    private spinner: NgxSpinnerService
  ) {
    this.authService.init(this.oktaAuth);
  }

  ngOnInit() {
    console.log("HomeComponent ngOnInit");
    this.authService.loginSubject.subscribe(
      (x) => {
        const currentUser = JSON.parse(sessionStorage.getItem("CurrentUser"));
        if (currentUser !== undefined && currentUser !== null) {
          this.authService["CurrentUser"] = currentUser;
        }
        if (x) {
          if (Object.keys(currentUser).indexOf("appRegistration") > 0) {
            let app: AppRegistration =
              this.authService["CurrentUser"].appRegistration ||
              <AppRegistration>{};
            console.log("appRegistration => ", Object.keys(sessionStorage).indexOf("adal.login.request") > 0);
            if (Object.keys(sessionStorage).indexOf("adal.login.request") > 0) {
              const adal_login_request =
                sessionStorage.getItem("adal.login.request");
              let isClientApp = adal_login_request.indexOf("client_id") != -1;
              if (isClientApp) {
                this.spinner.hide()
                if (app.tenantName == "iar_dashboard_gia-portal.cbre.com") {
                  this.router.navigate(["/tenant-app-dashboard"]);
                } else if (
                  app.tenantName == "missing_enrichment_gia-portal.cbre.com"
                ) {
                  this.router.navigate(["/missing-enrichment"]);
                } else if (
                  app.tenantName ==
                  "level3_validation_approve_reject_gia-portal.cbre.com"
                ) {
                  if (currentUser.userRolesList[0]["userTypeTitle"] == "User") {
                    // currentUser.appSettings.find(x=> x.appSettingName == 'UN_AUTHORIZED_MSG')['appSettingValue']
                    this.authorizationStatusMessage =
                      currentUser.appSettings.find(
                        (x) =>
                          x.appSettingName == "UN_AUTHORIZED_MSG_SELF_VALIDATION"
                      )["appSettingValue"];
                  }
                  this.router.navigate(["/validation-approval"]);
                } else {
                  this.routeRole();
                  //this.router.navigate(["/file-upload"]);
                }
              } else {
                this.routeRole();
                //this.router.navigate(["/file-upload"]);
              }
            } else {
              this.routeRole();
            }
          } else {
            this.routeRole();
            console.log("App registration not found.");
          }
        } else {
          if (
            this.authService.authorizationStatusMessage == "TOO_MANY_REQUESTS"
          ) {
            this.authorizationStatusMessage = currentUser.appSettings.find(
              (x) => x.appSettingName == "TOO_MANY_REQUESTS"
            )["appSettingValue"];
          } else {
            this.authorizationStatusMessage =
              this.authService.authorizationStatusMessage;
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  routeRole() {
    let roles = this.authService?.CurrentUser?.userTypeRoles;
    if (roles) {
      if (roles.isAdmin || roles.isReportManager) {
        this.router.navigate(["/file-submission-status"]);
      } else if (roles.isUser) {
        this.router.navigate(["/file-upload"]);
      } else {
        this.router.navigate(["/file-submission-status"]);
      }
    }
  }
}
