<footer>
  <div class="info">
    <div><a href="http://www.cbre.com/about/disclaimer-terms-of-use" target="_blank">Terms of Use</a></div>
    <div><a href="http://www.cbre.com/about/privacy-policy" target="_blank">Privacy Policy</a></div>
   
  </div>
  <div class="company info">
    <div>&copy; 2024 CBRE, Inc.</div>
    <div>App Version: {{version}}</div>
  </div>

</footer>
