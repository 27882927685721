import { AuthzUserInfo } from "../models/AuthzUserInfo.model";
import { BaseService } from "./baseService";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { CommonUtilityService } from "./common.utility.service";
import { Injectable } from "@angular/core";
import { UserService } from "./user.service";

import { NgxSpinnerService } from "ngx-spinner";
import OktaAuth from "@okta/okta-auth-js";

@Injectable()
export class AuthService extends BaseService {
  private loginId: string;
  public isAdmin = false;
  public isReportManager = false;
  public isAiUser = false;
  public isUser = false;
  public userInfo = {};
  public isCCDirector = false;
  ccdirectorRole = "CC Director";
  adminRole = "Administrator";
  public loginSubject = new BehaviorSubject<boolean>(false);
  public loginNotificaton = new Subject<string>();
  public authorizationStatusMessage: string;

  constructor(
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private cutlsrv: CommonUtilityService
  ) {
    super();
  }

  getisUser() {
    console.log("test", this.userInfo);
    if (Object.keys(this.userInfo).length === 0) {
      console.log("current user details not found");
      return false;
    } else {
      console.log(this.userInfo["userTypeRoles"]["isUser"]);
      return this.userInfo["userTypeRoles"]["isUser"];
    }
  }
  init(paramOktaAuth: OktaAuth) {
    this.oktaAuth = paramOktaAuth;
    this.spinner.show();
    if(sessionStorage.getItem("adal.login.request") == null){
      sessionStorage.setItem("adal.login.request", window.location.href);
    }
    this.oktaAuth.authStateManager.subscribe((authState) => {
      if (authState.isAuthenticated) {
        this.oktaAuth.getUser().then((user) => {
          console.log('Authenticated user', user);
          this.loginId = user.email;
          var client_id = "";
          if (Object.keys(sessionStorage).indexOf("adal.login.request") > 0) {
            const adal_login_request = sessionStorage.getItem("adal.login.request");
            if (adal_login_request.indexOf("client_id") != -1) {
              client_id = this.cutlsrv.getUrlParameter(
                "client_id",
                adal_login_request
              );
            }
          }
          this.userService.getUserByLoginId(this.loginId, client_id).subscribe(
            (data) => {
              this.spinner.hide();
              let authzUserInfo = data.body as AuthzUserInfo;
              console.log(authzUserInfo);
              this.CurrentUser = authzUserInfo;
              if (authzUserInfo.giaDataStatusCode === 200) {
                this.userInfo = this.CurrentUser;
                authzUserInfo.isAuthenticated = true;
                authzUserInfo.displayName = user.name;
                if (authzUserInfo.displayName === "") {
                  authzUserInfo.displayName =
                    authzUserInfo.lastName + ", " + authzUserInfo.firstName;
                }
                if (
                  authzUserInfo.userRolesList.find(
                    (r) => r.userTypeTitle === "Administrator"
                  )
                ) {
                  authzUserInfo.isUserAnAdmin = true;
                  this.isAdmin = true;
                } else {
                  authzUserInfo.isUserAnAdmin = false;
                  this.isAdmin = false;
                }
                //  Report Manager Code Checking
                if (
                  authzUserInfo.userRolesList.find(
                    (r) => r.userTypeTitle === "Report Manager"
                  )
                ) {
                  authzUserInfo.isUserAnReportManager = true;
                  this.isReportManager = true;
                } else {
                  authzUserInfo.isUserAnReportManager = false;
                  this.isReportManager = false;
                }

                if (
                  authzUserInfo.userRolesList.find(
                    (r) => r.userTypeTitle === "AI User"
                  )
                ) {
                  authzUserInfo.isAiUser = true;
                  this.isAiUser = true;
                } else {
                  authzUserInfo.isAiUser = false;
                  this.isAiUser = false;
                }

                // }else{
                // Set the current logged-in user.
                this.CurrentUser = authzUserInfo;
                this.loginSubject.next(true);
                // }
              } else if (authzUserInfo.giaDataStatusCode === 500) {
                authzUserInfo.isAuthenticated = false;
                this.authorizationStatusMessage =
                  authzUserInfo.giaDataFaultsBox.faultsList[0].faultText;
                this.loginSubject.next(false);
                this.spinner.hide()
              } else if (authzUserInfo.giaDataStatusCode === 401) {
                this.authorizationStatusMessage = authzUserInfo.appSettings.find(
                  (x) => x.appSettingName == "UN_AUTHORIZED_MSG"
                )["appSettingValue"];
              }
            },
            (error) => {
              if (error.status == 401) {
                this.authorizationStatusMessage = error["error"][
                  "appSettings"
                ].find((x) => x.appSettingName == "UN_AUTHORIZED_MSG")[
                  "appSettingValue"
                ];
              } else if (error.status == 429) {
                this.authorizationStatusMessage = "TOO_MANY_REQUESTS";
              } else if (error.status == 0) {
                this.authorizationStatusMessage = "TOO_MANY_REQUESTS";
              }
              this.loginSubject.next(false);
              this.spinner.hide()
              // this.router.navigate(['message']);
              // this.spinner.hide()
            }
          );
        });

      }
    });
  }
  logoutUser(paramOktaAuth: OktaAuth) {
    this.spinner.show();
    const signoutUrl = window.location.origin + '/'; // Replace with your desired signout URL
    this.oktaAuth.signOut({ postLogoutRedirectUri: signoutUrl }).then((res: any) => {
      console.log('Logout...')
      window.sessionStorage.clear();
    });
  }
}
