import { AfterViewInit, Component, Inject, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from "../environments/environment";

import {
  CommonConfirmationDialog,
  ConfirmationDialogData,
} from "./common-dialog/common.confirmation.dialog.component";
import { AuthService } from "./shared/auth.service";
import * as _ from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { OktaWebStorageService } from './shared/okta.web.storage.service';
import { NgxSpinnerService } from 'ngx-spinner';

const MINUTES_UNITL_AUTO_LOGOUT = environment.minutesUntilAutoLogout; // in Minutes
const CHECK_INTERVALL = 1000; // in ms
const STORE_KEY = "lastAction";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit {
  title = "GIA-Data File Delivery";
  lastAction: number;
  loggedOutPopupCall: boolean = false;
  authToken: string;
  private expirationKeyNumber: any;
  isAuthenticated: boolean = false;
  constructor(@Inject(OKTA_AUTH) private oktaAuth: OktaAuth,
    private ngZone: NgZone,
    private _router: Router,
    public dialog: MatDialog,
    private auth: AuthService,
    private oktaWebStorageService: OktaWebStorageService,
    private spinner: NgxSpinnerService) {
    this.spinner.show();
    this.expirationKeyNumber = Number(this.oktaWebStorageService.get_okta_access_tokene_expiresAt());

    this.lastAction = Date.now();
    this.checkAutoLogOut();
    this.initAutoLogOutListener();
    this.initAutoLogOutInterval();

  }

  safeOktaLogin() {
    this.oktaAuth.handleRedirect();
    this.oktaAuth.authStateManager.subscribe((authState) => {
      if (!authState.isAuthenticated) {
        console.log('Not authenticated');
        this.oktaAuth.signInWithRedirect().then(res => {
          console.log('Redirecting to Okta & submissions');
          this._router.navigate(['/']);
        });
      } else {        
        // this.auth.loginSubject.next(true);
        // this.spinner.hide();
        console.log('Authenticated & moving to home page - default submissions');
        // this._router.navigate(['/home']);
      }
    });
  }

  ngAfterViewInit() {
    console.log("you should check the user authentication here");
    this.safeOktaLogin();
  }

  initAutoLogOutListener() {

    this.ngZone.runOutsideAngular(() => {
      document.body.addEventListener("click", () => this.resetAutoLogOut());
      document.addEventListener("keypress", () => this.resetAutoLogOut());
    });
  }

  initAutoLogOutInterval() {
    this.ngZone.runOutsideAngular(() => {
      setInterval(() => {
        //this.paramLoggingSvc.logDebug("checking ....");
        this.checkAutoLogOut();
      }, CHECK_INTERVALL);
    });
  }

  resetAutoLogOut() {
    this.lastAction = Date.now();
  }

  checkAutoLogOut() {
    const now = Date.now();
    const timeleft = this.lastAction + MINUTES_UNITL_AUTO_LOGOUT * 60 * 1000;
    const diff = timeleft - now;
    const isTimeout = diff < 0;
    this.ngZone.run(() => {
      if (
        (isTimeout &&
          this.auth.CurrentUser.isAuthenticated &&
          !this.loggedOutPopupCall) ||
        (!_.isEmpty(this.expirationKeyNumber) &&
          this.expirationKeyNumber > 0 &&
          Date.now() / 1000 >= this.expirationKeyNumber - 60)
      ) {
        this.loggedOutPopupCall = true;
        console.log(
          "The system is going to log then out due to inactivity."
        );
        const dialogRef = this.dialog.open(CommonConfirmationDialog, {
          width: "650px",
          data: <ConfirmationDialogData>{
            title: `Inactivity Logout`,
            description: `The system is going to log then out due to inactivity.`,
            dialogData: "InactivityLogout",
          },
        });

        dialogRef
          .afterClosed()
          .subscribe((confirmation: ConfirmationDialogData) => {
            if (confirmation?.isOkClick) {
              console.log("Resetting auto logout.");
              this.resetAutoLogOut();
              this.loggedOutPopupCall = false;
            } else {
              console.log("User has been logout from sso session.");
              // this.safeOktaLogin();
              this.spinner.show();
              const signoutUrl = window.location.origin + '/'; // Replace with your desired signout URL
              this.oktaAuth.signOut({ postLogoutRedirectUri: signoutUrl }).then((res: any) => {
                window.sessionStorage.clear();
              });
            }
          });
      }
    });
  }
}
